import Carousel from 'react-slick';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

function NextArrow (props) {
  const { className, onClick, nextArrow } = props;
  return (
    <div onClick={onClick} className={className}>
      {nextArrow || <FiChevronRight />}
    </div>
  );
}
function PrevArrow (props) {
  const { className, onClick, prevArrow } = props;
  return (
    <div className={className} onClick={onClick}>
      {prevArrow || <FiChevronLeft />}
    </div>
  );
}

const Carousels = ({
  children,
  slideShow,
  infinite = true,
  showArrow = false,
  prevArrow,
  nextArrow
}) => {
  const arrows = showArrow
    // eslint-disable-next-line operator-linebreak
    ? // eslint-disable-next-line multiline-ternary
      {
        nextArrow: <NextArrow nextArrow={nextArrow} />,
        prevArrow: <PrevArrow prevArrow={prevArrow} />
      }
    : {};
  const settings = {
    ...arrows,
    dots: true,
    speed: 800,
    infinite: infinite,
    slidesToShow: slideShow || 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className='overflow-hidden'>
      <Carousel {...settings}>
        {children}
      </Carousel>
    </div>
  );
};

export default Carousels;

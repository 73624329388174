import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { NextSeo } from 'next-seo';
import { useFetch } from 'services/useFetch';
import { APP_IMG, APP_URL, SHOW_COMMEMORATE } from 'src/constants';
import { WEB_NAME } from 'src/constants/layout';
import { arrayEmpty, useDesktop } from 'src/utils/helper';
import Carousels from 'src/components/carousels';
import CardV from 'src/components/cards/card-v';
import _ from 'lodash';
import parse from 'html-react-parser';

const Layout = dynamic(import('src/components/layouts'), { ssr: false });

const PageError = ({ dataAdsHeader, sessionSevId }) => {
  const [dataLatestNews, setDataLatestNews] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const uri = '/api/v1.0/categories/latest-news';
        const { data } = await useFetch(uri);
        // console.log(data);
        if (!data) throw new TypeError(uri);
        setDataLatestNews(data.data);
      } catch (err) {
        console.error(`==========> 404 ERROR : ${err}`);
      }
    })();
  }, []);

  return (
    <>
      <NextSeo
        noindex={true}
        nofollow={true}
        title={WEB_NAME} />
      <Head>
        <link rel='canonical' href={`${APP_URL}`} />
        {/* -------------------------------- ADS : PPN ------------------------------- */}
        {!useDesktop() && !_.isEmpty(dataAdsHeader) ? parse(`${dataAdsHeader?.header_mobile}`) : ''}
        {useDesktop() && !_.isEmpty(dataAdsHeader) ? parse(`${dataAdsHeader?.header_desktop}`) : ''}
        {/* -------------------------------- ADS : PPN ------------------------------- */}
        {/* <script async type='text/javascript' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
          <script type='text/javascript' dangerouslySetInnerHTML={{
            __html: 'var googletag = googletag || {}; window.googletag.cmd = googletag.cmd || [];'
          }} /> */}
      </Head>
      <Layout sessionSevId={sessionSevId}>
        <div className='page-404' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
          <div className='container'>
            <div className='error-box'>
            <img className='no-article' src={`${APP_IMG}/images/tnews-preload.svg`} alt='no-article' />
            </div>
            <a href='/' className='error-back-home'> GO TO HOME PAGE </a>
              {!arrayEmpty(dataLatestNews) &&
                <Carousels slideShow={4}>
                  {dataLatestNews.slice(0, 5).map((item, index) =>
                  <div className='pe-0 pe-md-4'>
                    <CardV
                      key={index}
                      data={item}
                      path={item.link}
                    />
                    </div>
                  )}
                </Carousels>
              }
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PageError;

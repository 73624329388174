import { TiSocialFacebook } from 'react-icons/ti';
import {
  FaLine,
  FaYoutube
} from 'react-icons/fa';
import { FiInstagram } from 'react-icons/fi';
import { APP_IMG, APP_URL } from '.';
import { BsTwitterX } from 'react-icons/bs';

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */
export const THEME_WEB = '#0100BA';
export const WEB_TITLE = 'ข่าววันนี้ ข่าวด่วน ข่าวล่าสุด สดลึกจริง โดยสำนักข่าวทีนิวส์';
export const WEB_NAME = 'tnews';
export const WEB_DESC = 'สำนักข่าวทีนิวส์ รวบรวมข่าววันนี้ ข่าวด่วน ข่าวล่าสุดทั้งหมด เกี่ยวกับข่าวการเมือง ข่าวบันเทิง ข่าวสังคม ข่าวอาชญากรรม ข่าววาไรตี้ ข่าวภูมิภาค ข่าวต่างประเทศ ข่าวเศรษฐกิจ และข่าวกีฬา มาไว้ให้คุณ ที่นี่ที่เดียว';
export const WEB_KEYWORD = 'การเมือง,สังคม,บันเทิง,อาชญากรรม,วาไรตี้,ภูมิภาค,ต่างประเทศ,เศรษฐกิจ,กีฬา';

export const TWITTER = {
  HANDLE: 'ThaiNews – ไทยนิวส์',
  CREATOR: '@Thainews_News',
  SITE: '@Thainews_News'
};

export const FACBOOK = {
  ADMIN: '100000775096710',
  ID: '313270425990794',
  PAGE: '109027265795454'
};

/* ------------------------------- LOGO ------------------------------- */

export const LOGO_192 = 'Icon-192.png';
export const IMAGE_DEFAULT = `${APP_IMG}/default.jpg`;

/* -------------------------------------------------------------------------- */
/*                                   FOOTER                                   */
/* -------------------------------------------------------------------------- */

export const FOOTER_COPY_RIGHT = `Copyright Ⓒ ${new Date().getFullYear()} - Tnews.co.th All rights reserved.`;

export const WEB_URL = {
  WEB: `${APP_URL}/`,
  FACEBOOK: 'https://www.facebook.com/ThaiNews.co/',
  TWITTER: 'https://twitter.com/thainews_news',
  YOUTUBE: 'https://www.youtube.com/channel/UC0tGdIgAmZT-9P-I6vCJNFg',
  LINE: 'https://liff.line.me/1645278921-kWRPP32q/?accountId=hmx9435o',
  INSTAGRAM: 'https://www.instagram.com/thainews_online/'
};

export const SOCIAL_LIST = [
  { id: 1, icon: <TiSocialFacebook />, link: WEB_URL.FACEBOOK, name: 'facebook' },
  { id: 2, icon: <BsTwitterX className='--X' />, link: WEB_URL.TWITTER, name: 'X' },
  { id: 3, icon: <FaYoutube />, link: WEB_URL.YOUTUBE, name: 'youtube' },
  { id: 4, icon: <FaLine />, link: WEB_URL.LINE, name: 'line' },
  { id: 5, icon: <FiInstagram />, link: WEB_URL.INSTAGRAM, name: 'instagram' }
];

export const ADDRESS_ORG = {
  CONTENT: 'เลขที่ 1854 ชั้นที่ 7 ถ.เทพรัตน แขวงบางนาใต้ เขตบางนา',
  PROVINCE: 'กรุงเทพฯ',
  REGION: 'TH',
  POSTCODE: '10260',
  COUNTRY: 'THAILAND'
};

import { Provider } from 'react-redux';
import Head from 'next/head';
import PageError from 'pages/404';
import { store } from 'src/redux/store';

import 'src/assets/sass/style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  if (pageProps.statusCode >= 400) {
    return <Provider store={store}>
  <PageError sessionSevId={session} /></Provider>;
  }
  return (
    <>
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
        </Head>
        <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </>
  );
};

export default MyApp;

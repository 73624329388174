import dayjs from 'dayjs';
import 'dayjs/locale/th';
import numeral from 'numeral';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
export const useDesktop = () => useMediaQuery({ query: '(min-width: 1024px)' });
export const useMobile = () => useMediaQuery({ query: '(max-width: 1023px)' });

export const splitFootballScore = text => {
  const textArr = text.split('-');
  const left = textArr[0].replaceAll(' ', '');
  const right = textArr[1].replaceAll(' ', '');
  return { left: left, right: right };
};
export function dropSomeField (obj, fieldArray) {
  const object = { ...obj };
  for (let index = 0; index < fieldArray.length; index++) {
    delete object[fieldArray[index]];
  }
  return object;
}
export const timestamp = () => {
  return dayjs(Date.now()).format('YYYY-MM-DD HH:MM:ss');
};
export function useTimeUpdate (_date) {
  if (!_date) return null;
  return dayjs(_date).locale('th').add(543, 'year').format('HH.mm น.');
}
export function convertDatasetsLists (data, topic) {
  const results = [];
  data.forEach(el => {
    const indexEl = topic.indexOf(el.key);
    if (indexEl !== -1) {
      return results.push(el.value);
    }
  }, []);

  return results;
};
export const convertDatasets = (data, topic, isValue) => {
  const results = [];

  if (isValue) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key.startsWith(topic)) {
        results.push(data[i].value);
      }
    }
  } else {
    for (let i = 0; i < data.length; i++) {
      if (data[i].topic.startsWith(topic)) {
        results.push(data[i].dataSet);
      }
    }
  }

  return isValue ? results[0] : results;
};
export function rewriteTxt (_txt) {
  if (_txt?.indexOf('.') !== -1) return 'latest-news';
  switch (_txt) {
    case 'rss': return 'latest-news';
    default: return _txt;
  }
}

export const htmlEntities = (str) => {
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');
};

export const escapeChar = (str) => {
  if (typeof str !== 'string') return null;
  return str.replace(/[\\]/g, '').replace(/["]/g, '\\$&');
};

// TODO : DATE UPDATE
// useDateUpdate()
export function useDateUpdate (_date, _showTime) {
  if (!_date) return null;
  const _format = _showTime ? 'DD MMM YYYY |  HH:ss น.' : 'DD MMM YYYY';
  return dayjs(_date).locale('th').add(543, 'year').format(_format);
}

// TODO : RENDER PAGEVIEWS
// usePageViews(data);
export function usePageViews (_pageviews) {
  const result = _pageviews > 999 ? numeral(+_pageviews).format('0.0a') : _pageviews;
  // console.log({ result });
  return result;
};

//! ใช้สำหรับเช็คอาร์เรย์ที่ต้องการให้แสดงถ้ามีมากกว่า 1 ตัว
// TODO : check array is empty
export function arrayEmpty (_array) {
  if (!_array) return null;
  // console.log(_array.length);
  return (Array.isArray(_array) && _array.length < 1);
};

// TODO : convert object to path
export const convertObjPath = (_obj) => {
  if (!_obj || Object.keys(_obj).length < 1) return '';
  const entries = Object.entries(_obj);
  const itemData = encodeURI(`?${entries.join('&').split(',').join('=')}`);
  return itemData;
};

export const convertObjPathNoneEncode = _obj => {
  if (!_obj || Object.keys(_obj).length < 1) return '';
  const entries = Object.entries(_obj);
  const itemData = `?${entries.join('&').split(',').join('=')}`;
  return itemData;
};

export const convertArrPathLottery = (_obj) => {
  if (!Array.isArray(_obj.lottos) || _obj.lottos?.length === 0) return null;
  const feild = Object.keys(_obj)[0];
  const itemData = `?${feild}[]=${_obj.lottos.join(`&${feild}[]=`)}`;
  return itemData;
};

export const convertArrPath = (_obj, field) => {
  let result = '';
  if (!_.isArray(_obj)) return null;
  if (_obj?.length > 0) {
    result = `?${field}[]=${_obj.join(`&${field}[]=`)}`;
  } else {
    result = `?${field}=${_obj[0]}`;
  }
  return result;
};

// TODO :: Split text from an array by width
// ? SplitArrayText([{title:'text'}]) => [{title:'text'}]
// ? SplitArrayText([{title:'text'}],'title') => return ['text']
// ? SplitArrayText([{title:'text'}],'title', 2) => return ['te...']

export function SplitArrayText (array = [], name = '') {
  const length = Math.floor(window.innerWidth / 7.5);
  // console.log('SplitArrayText', length);
  if (!name) {
    return array;
  }

  return array.map((el) => el[name].length > length ? `${el[name].substring(0, length - 1)}...` : el[name]);
}

export function convertBadge (data, format) {
  if (format) return `${data?.section?.th}/${data?.subCategory?.th}`;
  return data?.subCategory?.th ? data?.subCategory?.th : data?.section?.th;
}
export const timeAgo = _date => {
  if (!_date) return null;
  const dateNow = dayjs(_date).locale('th');
  const diffStr = dateNow.from(new Date());
  return diffStr;
};

export function htmlToPlainText (html) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, 'text/html');
  return dom.body.textContent || '';
}

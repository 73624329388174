import { API_URI } from 'src/constants';

// TODO : useFetch('');
export async function useFetch (url, apiUri) {
  const checkUri = url ? `${API_URI}${url}` : apiUri;
  // console.log('URI', checkUri);
  try {
    const response = await fetch(checkUri, {
      method: 'GET',
      headers: {
        // Solved issues call OPTIONS
        'Content-Type': 'text/plain'
      }
    });
    const data = await response.json();
    // console.log('data', data);
    if ('msg' in data) {
      return { ...data };
    }
    return { data };
  } catch (err) {
    console.log(`==========> USE_FETCH ERROR : ${checkUri}`);
  }
};

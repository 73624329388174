import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  result: null,
  lotto: null
};
export const navSlice = createSlice({
  name: 'navData',
  initialState: initialState,
  reducers: {
    LOTTO_DATA: (state, action) => {
      state.lotto = action.payload;
    },
    TAGS_DATA: (state, action) => {
      state.tags = action.payload;
    },
    FOOTER_DATA: (state, action) => {
      state.footer = action.payload;
    },
    NAV_DATA: (state, action) => {
      state.result = action.payload;
    }
  }
});
export const { LOTTO_DATA, NAV_DATA, TAGS_DATA, FOOTER_DATA } = navSlice.actions;
export default navSlice.reducer;
// action structure is {
//     "type": "subscribe/specificDecrement",
//     "payload": "aa"
// }

import getConfig from 'next/config';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();

export const APP_ENV = publicRuntimeConfig.NEXT_PUBLIC_APP_ENV || 'production';
export const API_URI = process.env.API_URL || publicRuntimeConfig.NEXT_PUBLIC_API_URL;
export const APP_URL = publicRuntimeConfig.NEXT_PUBLIC_APP_URL;
export const APP_IMG = publicRuntimeConfig.NEXT_PUBLIC_IMG;
export const SERVER_GSERVICE_URL = process.env.SERVER_GSERVICE_URL || publicRuntimeConfig.NEXT_PUBLIC_SERVER_GSERVICE_URL;
export const CLIENT_GSERVICE_URL = process.env.CLIENT_GSERVICE_URL || publicRuntimeConfig.NEXT_PUBLIC_CLIENT_GSERVICE_URL;

export const NEXT_DAILYMOTION_ID = publicRuntimeConfig.NEXT_DAILYMOTION_ID;
export const SHOW_COMMEMORATE = publicRuntimeConfig.SHOW_COMMEMORATE || process.env.SHOW_COMMEMORATE;
export const LAST_ARTICLE_ID = process.env.LAST_ARTICLE_ID;
export const NEXT_DEFAULT_COUNT_VIEW = process.env.NEXT_DEFAULT_COUNT_VIEW;

export const isProd = APP_ENV.toLowerCase() === 'production';
export const IS_NO_ROBOT_INDEX = !isProd;
// export const IS_SHOW_COOKIE = publicRuntimeConfig.IS_SHOW_COOKIE || process.env.IS_SHOW_COOKIE;

/* ---------------------------------- GA_ID --------------------------------- */
export const GOPT_ID = process.env.NEXT_PUBLIC_GOPT_ID || publicRuntimeConfig.NEXT_PUBLIC_GOPT_ID;
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || publicRuntimeConfig.NEXT_PUBLIC_GTM_ID;

/* ---------------------------- RECAPTCHA_SIT_KEY ------------------------------ */
export const RECAPTCHA_SIT_KEY = '6LcOx9kcAAAAAEtzpelF8yxPDGnmU1B5hIbE7RzW';
export const RECAPTCHA_SECRET_KEY = '6LcOx9kcAAAAACzU2wSo8pgIdt-N0wrp7NOVQzBT';

export const ADS_IMG_320 = `${process.env.NEXT_PUBLIC_IMG}/static/ads-320.jpg`;
export const ADS_IMG_728 = `${process.env.NEXT_PUBLIC_IMG}/static/ads-728.jpg`;

/* ---------------------------- RECAPTCHA ---------------------------- */
// speech
export const NEXT_PUBLIC_URL_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_SOUND;
export const NEXT_PUBLIC_URL_PATH_SOUND = publicRuntimeConfig.NEXT_PUBLIC_URL_PATH_SOUND;
export const NEXT_PUBLIC_WEB_NAME_SOUND = publicRuntimeConfig.NEXT_PUBLIC_WEB_NAME_SOUND;
export const NEXT_PUBLIC_ID_MOCK_SOUND = publicRuntimeConfig.NEXT_PUBLIC_ID_MOCK_SOUND;
export const NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TITLE_SUOND;
export const NEXT_PUBLIC_PLACEHOLDER_TIME_OUT = publicRuntimeConfig.NEXT_PUBLIC_PLACEHOLDER_TIME_OUT;

export const AUTH0_BASE_URL = serverRuntimeConfig.AUTH0_BASE_URL;
export const AUTH0_ISSUER_BASE_URL = serverRuntimeConfig.AUTH0_ISSUER_BASE_URL;
export const AUTH0_CLIENT_ID = serverRuntimeConfig.AUTH0_CLIENT_ID;
export const AUTH0_CLIENT_SECRET = serverRuntimeConfig.AUTH0_CLIENT_SECRET;
export const WEB_NAME = publicRuntimeConfig.WEB_NAME;
export const REDIS_HOST = serverRuntimeConfig.REDIS_HOST;
export const REDIS_PASSWORD = serverRuntimeConfig.REDIS_PASSWORD;
export const REDIS_TTL = serverRuntimeConfig.REDIS_TTL;

export const NEXT_PUBLIC_LOGIN_COOKIE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_COOKIE_DATE || 1;
export const NEXT_PUBLIC_LOGIN_STATE_DATE = publicRuntimeConfig.NEXT_PUBLIC_LOGIN_STATE_DATE || 1;
export const NEXT_PUBLIC_SPEED_KEYPOINT = publicRuntimeConfig.NEXT_PUBLIC_SPEED_KEYPOINT;
